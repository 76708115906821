<template>
  <div class="product-sort">
    <head-nav></head-nav>
    <product-img></product-img>
    <product-nav></product-nav>

    <footers></footers>
  </div>
</template>

<script>
import HeadNav from './head-nav';
import productImg from './product-img';
import productNav from './product-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    productImg,
    productNav,
    Footers,
  },
  data() {
    return {
      acticteTab: 0,
    };
  },
  methods: {
    onTabChange(activateTab) {
      this.acticteTab = activateTab;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
