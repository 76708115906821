<template>
  <div
    class="product"
    :class="mask?'product-mask':''"
  >
    <div
      class="product-Choice"

    >
      {{productNav}} <i
        class="iconfont "
        :class="mask?'icon-arrow-up':'icon-botton'"
         @click="select"
      ></i>
    </div>

      <div
        class="product-Choice-list"
        :class="mask?'product-Choice-lists':''"
        v-for="(item, index) in productCategorieList"
        :key="index"
      >
        <div
          class="product-nav-list-title-info"
          @click="onChoice(item.name,item.id)"
        >
          {{item.name}}
        </div>
      </div>

    <div class="product-nav">
      <div
        class="product-nav-list "
        v-for="(item, index) in produnctGroupList"
        :key="index"
      >
        <NavTop
          :item="item"
          v-if="!item.list"
        />
      </div>

            <div
        class="product-Choice-mask"
        :class="mask?'product-Choice-masks':''"
      ></div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import NavTop from './componets/nav-top';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    NavTop,
  },
  apollo: {
    productCategorieList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              productCategorieList: allProductCategories {
                id
                name

                productGroups {
                  id
                  name
                }
              }
            }
          `;
        }
        return gql`
          query {
            productCategorieList: allEnProductCategories {
              id
              name

              productGroups {
                id
                name
              }
            }
          }
        `;
      },
    },
    produnctGroupList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query($id: ID!) {
              produnctGroupList: allProductGroups(where: { productCategoryId: { id: $id } }) {
                id
                name
                products {
                  id
                  name
                }
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query($id: ID!) {
            produnctGroupList: allEnProductGroups(where: { productCategoryId: { id: $id } }) {
              id
              name
              products {
                id
                name
              }
              image {
                publicUrl
              }
            }
          }
        `;
      },
      variables() {
        return {
          id: this.currentProductCategoryId,
        };
      },
      skip() {
        return !this.currentProductCategoryId;
      },
    },
  },
  watch: {
    productCategorieList(value) {
      if (value.length > 0) {
        this.currentProductCategoryId = value[0].id;
        this.productNav = value[0].name;
      }
    },
  },
  data() {
    return {
      mask: false,
      currentProductCategoryId: '',
      productNav: '',
      productChoice: '',
      productCategorieList: [],
      produnctGroupList: [],
      list: [],
    };
  },
  methods: {
    select() {
      this.mask = !this.mask;
    },
    onChoice(onChoiceName, onChoiceId) {
      this.currentProductCategoryId = onChoiceId;
      this.productNav = onChoiceName;
      this.mask = !this.mask;
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  .product-Choice {
    width: 100%;
    text-align: center;
    justify-content: center;
    height: 80px;
    line-height: 80px;
    background: #ffbf00;
    position: relative;

    .iconfont {
      font-size: 40px;
      position: absolute;
      right: 40px;
      top: 0;
    }

  }

    .product-Choice-list {
      width: 100%;
      text-align: center;
      background: #fff;
      display: none;
      z-index: 9;
      height: 80px;
      line-height: 80px;
    }

    .product-Choice-lists {
      display: block;
    }

  .product-nav {

    padding-bottom: 120px;
    position: relative;

    &-list {
    margin: 0 30px;
      line-height: 80px;
      color: #333333;
      margin-top: 30px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      font-size: 36px;
    }

        .product-Choice-mask {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0.7;
      height: 100%;
      width: 100%;
      display: none;
    }

      .product-Choice-masks {
      display: block;
    }
  }
}
.product-mask {
  overflow: hidden;
}
</style>
