<template>
  <div class="product-nav-list-title">
    <div
      @click="onProductCategoryClick(item.id)"
      class="product-nav-lists"
    >
      <div class="product-nav-list-title-info">{{item.name}}</div>
    </div>

    <div class="list-option-box">
      <div
        class="list-option "
        v-for="(child, index) in item.products"
        :key="index"
      >
        <div
          class="list-option-info"
          @click="son(index)"
        >
          <span @click="onProductCategoryItemClick(child.id,child.name)">
            <i class="iconfont icon-yuan"></i>{{child.name}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      indexpage: 0,
      indexson: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  watch: {},
  methods: {
    onProductCategoryClick(index) {
      this.isOpen = !this.isOpen;
      this.indexpage = index;
    },

    onProductCategoryItemClick(listId, listName) {
      this.$router.push({
        name: 'product-sort-details',
        params: {
          listId,
          listName,
        },
      });
    },

    son(index) {
      this.indexson = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-nav-list-title {
  &-info {
    text-align: center;
    background: #eeeeee;
  }

  .list-option-box {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .list-option {
      margin: 0 4px;
      &:active {
        .list-option-info {
          color: #ffbf00;

          .iconfont {
            color: #ffbf00;
          }
        }
      }
      .list-option-info {
        color: #404040;
        font-size: 28px;

        .iconfont {
          font-size: 30px;
          margin-right: 4px;
          color: #eee;
        }
      }
    }
  }
}
</style>
